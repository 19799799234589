import {Link, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
  ArticleStatus,
  EditorType,
  EventType,
  GetArticleDocument,
  MailInfoFragment,
  RefereeInfoFragment,
  RefereeRequestStatus,
  TemplateType,
  UserAction
} from "generated/graphql";
import {Button, Checkbox, Col, Descriptions, Dropdown, MenuProps, Modal, Popover, Row, Upload} from "antd";
import {formatDate, formatDateTime, formatEvent, formatStatus} from "utilities/formatters";
import {DataTable} from "components/Table/DataTable";
import {useState} from "react";
import {DialogButton} from "components/Modal/DialogButton";
import {AddRefereeDialog} from "routes/Editor/Dialogs/AddRefereeDialog";
import {MailDialog, Recipient} from "components/Modal/MailDialog";
import {formatValue, isClosed} from "utilities/enumHelpers";
import {VerticalSpace} from "components/Layout/VerticalSpace";
import {AddCommentDialog} from "routes/Editor/Dialogs/AddCommentDialog";
import {EditStatusDialog} from "routes/Editor/Dialogs/EditStatusDialog";
import {useUpdateRefereeRequest} from "hooks/useUpdateRefereeRequest";
import {RefereeReportDialog} from "routes/Editor/Dialogs/RefereeReportDialog";
import {ExtendRequestDialog} from "routes/Editor/Dialogs/ExtendRequestDialog";
import {FileLink} from "components/Navigation/FileLink";
import {useJournal} from "hooks/useJournal";
import {ViewMailDialog} from "routes/Editor/Dialogs/ViewMailDialog";
import {FormDialog} from "routes/Dialogs/FormDialog";
import {ViewFormDialog} from "routes/Dialogs/ViewFormDialog";
import {AssignEditorDialog} from "routes/Editor/Dialogs/AssignEditorDialog";
import {FormQuestion, ViewAnswer} from "components/Layout/ViewAnswer";
import {useAuth} from "hooks/useAuth";
import {CopyOutlined, ExclamationCircleFilled, PaperClipOutlined} from "@ant-design/icons";
import {FlagButton} from "components/Input/FlagButton";
import {EditTitleDialog} from "routes/Editor/Dialogs/EditTitleDialog";
import {articleOrder} from "routes/Editor/ArticleList";
import {UserPickElement} from "components/Picker/UserPickElement";
import {useEditArticleData} from "hooks/useEditArticleData";
import {ReplaceFileDialog} from "routes/Editor/Dialogs/ReplaceFileDialog";
import dayjs from "dayjs";

const {Item} = Descriptions;
const {confirm} = Modal;

interface MailInfo {
  type: TemplateType
  request?: RefereeInfoFragment
  recipient?: Recipient
  replyTo?: MailInfoFragment,
  subject?: string
}

const decisionStatuses = [
  ArticleStatus.MinorRevision, ArticleStatus.MajorRevision,
  ArticleStatus.Accepted, ArticleStatus.Rejected
];

export const ArticleDetail = () => {
  const {number} = useParams();
  const {data, loading} = useQuery(GetArticleDocument, {variables: {number: +(number ?? '0')}});

  const [mailInfo, setMailInfo] = useState<MailInfo | null>(null);

  const article = data?.article;
  const journal = useJournal();

  const { can } = useAuth();

  const editArticleData = useEditArticleData();

  // referee menu
  const [requestDialog, setRequestDialog]
    = useState<{ submit?: boolean, extend?: boolean, replaceFile?: boolean, referee: string, id: number, submissionId?: number | null } | null>(null)
  const { setStatus, setHideReport } = useUpdateRefereeRequest();

  const getRefereeOptions = (r: RefereeInfoFragment): MenuProps['items'] => {
    const opts: MenuProps['items'] = [];
    if (r.status === RefereeRequestStatus.Sent) {
      opts.push({
        label: 'Mark as refereeing',
        key: 'refereeing',
        onClick: () => setStatus(r.id, RefereeRequestStatus.Refereeing)
      });
    }
    if (r.status === RefereeRequestStatus.Sent || r.status === RefereeRequestStatus.Refereeing) {
      opts.push({
        label: 'Close',
        key: 'close',
        onClick: () => setStatus(r.id, RefereeRequestStatus.Closed)
      }, {
        label: r.status === RefereeRequestStatus.Sent ? 'Close - no reply' : 'Close - not received',
        key: 'noreply',
        onClick: () => setStatus(r.id, r.status === RefereeRequestStatus.Sent
          ? RefereeRequestStatus.NoReply : RefereeRequestStatus.NotReceived)
      }, {
        label: 'Submit report',
        key: 'submit',
        onClick: () => setRequestDialog({ id: r.id, submit: true, submissionId: r.formSubmissionId, referee: r.referee.displayName })
      }, {
        label: 'Extend deadline',
        key: 'extend',
        onClick: () => setRequestDialog({ id: r.id, extend: true, referee: r.referee.displayName })
      }, {
        label: 'Send reminder',
        key: 'reminder',
        onClick: () => setMailInfo({ type: TemplateType.RefereeReminder, request: r, recipient: r.referee })
      });
    }
    if (r.status === RefereeRequestStatus.Completed && !r.report && can(UserAction.ManageArticles)) {
      opts.push({
        label: 'Add report',
        key: 'submit',
        onClick: () => setRequestDialog({ id: r.id, submit: true, submissionId: r.formSubmissionId, referee: r.referee.displayName })
      })
    }
    if (isClosed(r.status)) {
      opts.push({
        label: 'Reinstate',
        key: 'reinstate',
        onClick: () => setStatus(r.id, RefereeRequestStatus.Sent)
      });
    }
    if (r.status === RefereeRequestStatus.Completed && can(UserAction.OverrideRequestStatus)) {
      opts.push({
        label: 'Mark as closed',
        key: 'closed',
        onClick: () => confirm({
          title: 'Mark as closed',
          content: `Are you sure you want to the referee request for ${r.referee.displayName} to closed?`,
          icon: <ExclamationCircleFilled />,
          onOk: () => setStatus(r.id, RefereeRequestStatus.Closed),
          onCancel: () => {}
        })
      });
    }
    if (r.report && can(UserAction.ManageAssignedArticles)) {
      opts.push({
        label: r.hideReport ? "Unset editors only" : "Editors only",
        key: 'hide',
        onClick: () => setHideReport(r.id, !r.hideReport)
      });
    }
    opts.push({
      label: 'Send e-mail',
      key: 'email',
      onClick: () => setMailInfo({ type: TemplateType.RefereeMail, request: r, recipient: r.referee })
    });
    if (can(UserAction.ReplaceFiles) && r.report) {
      opts.push({
        label: 'Replace file',
        key: 'replace',
        onClick: () => setRequestDialog({ replaceFile: true, id: r.id, referee: r.referee.displayName })
      })
    }
    return opts;
  }

  const formRequests = article?.refereeRequests.filter(r => r.formSubmissionId && r.formSubmission?.dateSubmitted) ?? [];
  const decisions = article?.events.filter(e => e.type === EventType.ChangeStatus && e.status && decisionStatuses.includes(e.status));

  const orderIndex = articleOrder.indexOf(article?.number ?? -1);

  return (
    <>
      {loading && <i>Loading, please wait...</i>}
      {article && <>{ orderIndex >= 0 && <div style={{ float: "right", display: "flex", gap: "20px", marginRight: "20px" }}>
        { orderIndex > 0 && <Link to={`/editor/article/${articleOrder[orderIndex -1]}`}>Previous</Link> }
        { orderIndex + 1 < articleOrder.length && <Link to={`/editor/article/${articleOrder[orderIndex + 1]}`}>Next</Link> }
      </div> }
        <Row gutter={32}>
            <Descriptions bordered column={1}>
                <Item label="Manuscript">{journal.code} {article.number}&nbsp;&nbsp; { can(UserAction.ManageAssignedArticles) && <FlagButton inline article={article} />}</Item>
                <Item label="Managing editor">{article.managingEditor?.displayName}
                  { !article.managingEditor && <i>Not assigned</i> }
                  { can(UserAction.ManageArticles) && <DialogButton dialog={props =>
                      <AssignEditorDialog {...props}
                        articleId={article.id} type={EditorType.Managing} userId={article?.managingEditor?.id} />}>(assign)</DialogButton> }
                </Item>
                { !journal.featureSettings.disableHandlingEditor &&
                  <Item label="Handling editor">{article.handlingEditor?.displayName}
                    { !article.handlingEditor && <i>Not assigned</i> }
                    { can(UserAction.ManageArticles) && <DialogButton dialog={props =>
                        <AssignEditorDialog {...props}
                                            articleId={article.id} type={EditorType.Handling}
                                            userId={article?.handlingEditor?.id}
                                            onComplete={res => setMailInfo({
                                              type: TemplateType.HandlingEditor,
                                              recipient: res
                                            })}
                        />}>
                        (assign)
                    </DialogButton> }
                  </Item>
                }
                <Item label="Corresponding author">
                  { can(UserAction.EditArticleAuthor) ? <UserPickElement value={article.author}
                                   buttonType="link" buttonStyle={{ padding: "0" }}
                                   onChange={o => editArticleData({ articleId: article?.id, authorId: o.id })}
                  /> : article.author.displayName }
                  { can(UserAction.ManageAssignedArticles) && <Button type="link"
                            onClick={() => setMailInfo({ type: TemplateType.Author, recipient: article?.author })}>
                    (send e-mail)
                  </Button> }
                  <Button type="link" title="Copy mail address" style={{ padding: 0 }}
                          onClick={() => article?.author.mailAddress && navigator.clipboard.writeText(article?.author.mailAddress)}>
                    <CopyOutlined />
                  </Button>
                </Item>
                <Item label="Authors"> {article.authors.map(a => a.displayName).join(', ')} </Item>
                <Item label="Title">{article.title}
                  { can(UserAction.ManageArticles) && <DialogButton dialog={props => <EditTitleDialog {...props} articleId={article.id} title={article?.title} />}>(edit)</DialogButton> }
                </Item>
                <Item label="Status">{formatStatus(article.status)}
                  { can(UserAction.ManageArticles) && <DialogButton dialog={props =>
                    <EditStatusDialog {...props} articleId={article.id} allowEditorNotification={!!article?.handlingEditor}
                                      onNotify={() => setMailInfo({ type: TemplateType.Author, recipient: article?.author })} />
                  }>(edit)</DialogButton> }
                </Item>
                <Item label="Last event">{formatEvent(article.events[0])}</Item>
              { journal.featureSettings.useCopyrightAgreement && <>
                  <Item label="Copyright agreement">
                  { article.copyrightAgreement && <FileLink file={article.copyrightAgreement} /> }
                  { !article.copyrightAgreement && can(UserAction.ManageArticles) &&
                      <Upload accept=".pdf"
                          beforeUpload={file => { editArticleData({ articleId: article.id, copyrightAgreement: file }); return false; }}>
                          <Button type="link" style={{ padding: "0" }}>Upload file</Button>
                      </Upload> }
                  </Item>
                  { can(UserAction.ManageArticles) && <Item label="Lock article">
                      <Checkbox checked={article.isLocked} onChange={e => editArticleData({ articleId: article?.id, isLocked: e.target.checked })} />
                  </Item> }
              </> }
            </Descriptions>
        </Row>

        <Row gutter={32}>
            <Col span={12}>
                <h3>Versions</h3>
                <DataTable source={article.versions} rowKey="version" columns={[
                  {
                    key: 'Version',
                    value: v => v.version
                  },
                  {
                    key: 'Date',
                    value: v => v.dateSubmitted,
                    type: "date"
                  },
                  {
                    key: 'File',
                    render: v => <FileLink file={v.manuscript} />
                  }
                ]} />
                <VerticalSpace />
                { can(UserAction.ManageAssignedArticles) &&
                  <Link to="./revision"><Button>Upload new version</Button> </Link>
                }
            </Col>

          { !!decisions?.length && <Col span={10}>
                <h3>Status</h3>
                <DataTable source={decisions} columns={[
                  {
                    key: 'Version',
                    value: e => e.version
                  },
                  {
                    key: 'Date',
                    value: e => e.date,
                    type: "date"
                  },
                  {
                    key: 'Status',
                    value: e => formatStatus(e.status!)
                  }
                ]} />
            </Col>
          }
        </Row>

        <h3>Referees</h3>
        <DataTable source={article.refereeRequests} columns={[
          {
            key: 'Version',
            value: v => v.version
          },
          {
            key: 'Referee',
            value: v => v.referee.displayName,
            render: v => can(UserAction.ViewUsers) ? <Link to={`/editor/users/${v.referee.id}`}>{v.referee.displayName}</Link> : <>{v.referee.displayName}</>
          },
          {
            key: 'Type',
            value: v => formatValue(v.type)
          },
          {
            key: 'Date requested',
            value: v => v.dateRequested,
            type: "date"
          },
          {
            key: 'Status',
            value: v => formatValue(v.status),
            render: v => <>
              {formatValue(v.status)}
              {v.status === RefereeRequestStatus.Sent && <> ({dayjs().diff(dayjs(v.dateRequested), 'day')} days ago)</> }
            </>
          },
          {
            key: 'Deadline/received',
            value: v => v.dateEnd,
            render: v => <span style={{ color: v.status === RefereeRequestStatus.Refereeing && dayjs(v.dateEnd) < dayjs() ? "red" : undefined }}>
              {!isClosed(v.status) && formatDate(v.dateEnd)}
              {v.status === RefereeRequestStatus.Refereeing && dayjs(v.dateEnd) < dayjs() && <> ({dayjs().diff(dayjs(v.dateEnd), 'day')} days overdue)</>}
            </span>,
            type: "date"
          },
          {
            key: 'Report',
            render: v => <>
              <FileLink file={v.report} />
              {v.formSubmissionId && v.status === RefereeRequestStatus.Completed &&
                  <DialogButton dialog={props => <ViewFormDialog {...props} submissionId={v.formSubmissionId ?? 0} />}>View</DialogButton> }
            </>
          },
          {
            key: 'Action',
            header: <></>,
            render: v => <Dropdown menu={{ items: getRefereeOptions(v) }}>
              <Button type="link">Actions</Button>
            </Dropdown>
          }
        ]} />
        <RefereeReportDialog open={!!requestDialog?.submit} onClose={() => setRequestDialog(null)}
                             refereeRequestId={requestDialog?.id} referee={requestDialog?.referee} />
        <ExtendRequestDialog open={!!requestDialog?.extend} onClose={() => setRequestDialog(null)}
                             refereeRequestId={requestDialog?.id} />
        <ReplaceFileDialog open={!!requestDialog?.replaceFile} onClose={() => setRequestDialog(null)}
                           refereeRequestId={requestDialog?.id} referee={requestDialog?.referee} />
        <FormDialog open={!!requestDialog?.submissionId} onClose={() => setRequestDialog(null)}
                    submissionId={requestDialog?.submissionId ?? 0} />
        <VerticalSpace />
        { can(UserAction.ManageAssignedArticles) &&
          <DialogButton type='default' dialog={props => <AddRefereeDialog
            {...props} articleId={article.id} articleNumber={article?.number}
            onCreate={req => setMailInfo({ type: TemplateType.RefereeRequest, request: req, recipient: req.referee })} />}
          >
              Assign referee
          </DialogButton>
        }

        { formRequests.length > 0 && <><h3>Quick opinion form results</h3>
          <DataTable source={formRequests[0]?.formSubmission?.form.questions ?? []} columns={[{
            key: 'Question',
            value: r => r.identifier,
            render: r => <Popover content={r.text}>{r.identifier}</Popover>
          }, ...(formRequests.map(q => ({
            key: q.referee.displayName,
            render: (r: FormQuestion) => <ViewAnswer sub={q.formSubmission} question={r} />
          })) ?? [])
          ]} />
         </>}

        <h3>Correspondence</h3>
        <DataTable source={article.mails}
                   pagination={{
                     defaultPageSize: 5,
                     showTotal: total => `${total} messages`,
                     showSizeChanger: true
                   }}
                   columns={[
          {
            key: 'Date',
            value: a => a.date,
            render: a => <div style={{ whiteSpace: "nowrap" }}>{formatDateTime(a.date)}</div>
          },
          {
            key: 'From',
            value: a => a.from?.displayName ?? '',
            render: a => a.from ? <>{a.from?.displayName}</> : <i>System</i>
          },
          {
            key: 'To',
            value: a => a.to.map(r => r.displayName ?? r.mailAddress).join(', ')
          },
          {
            key: 'Subject',
            value: a => a.subject,
            render: a => <>
              <DialogButton dialog={(props) => <ViewMailDialog mail={a} {...props} />}>{a.subject}</DialogButton>
              { a.attachments.length > 0 && <PaperClipOutlined /> }
            </>
          },
          {
            key: '',
            render: a => <>{ a.from &&
                <Button type="link"
                        onClick={() => setMailInfo({ type: TemplateType.Other, replyTo: a })}>
                  Reply
                </Button> }</>
          }
        ]} />
        { can(UserAction.ManageAssignedArticles) && <Button style={{ position: "relative", top: article.mails.length > 0 ? "-40px" : "5px" }} onClick={() => setMailInfo({
          type: TemplateType.EditorMail,
          recipient: { mailAddress: journal.editorAddress, displayName: 'Editors' },
          subject: `${journal.code} ${article?.number}`
        })}>Send email about this paper</Button> }

        <h3 style={{ marginTop: article.mails.length > 0 ? "-20px" : "" }}>Events and editor comments log</h3>
        { can(UserAction.ManageAssignedArticles) &&
          <DialogButton type='default' dialog={props => <AddCommentDialog {...props} articleId={article.id} />}>Add comment</DialogButton>
        }
        <VerticalSpace />
        <DataTable source={article.events} columns={[
          {
            key: 'Event',
            render: v => <>
              { v.type === EventType.AddComment && <>Comment: <span className="comment">{v.comment}</span></> }
              { v.type !== EventType.AddComment && <>{formatEvent(v)}<div className="comment">{v.comment}</div></> }
              { v.files.length > 0 && <div style={{ marginTop: 5 }}>
                  Attachment: <a href={v.files[0].url} target="_blank" rel="noreferrer">{v.files[0].name}</a>
              </div> }
            </>
          },
          {
            key: 'Date',
            value: v => v.date,
            type: "date",
            width: '140px'
          },
          {
            key: 'User',
            value: v => v.user.displayName,
            width: '180px'
          },
          {
            key: 'Version',
            value: v => v.version
          }
        ]} />

        <MailDialog type={mailInfo?.type}
                    key={`${mailInfo?.type}_${mailInfo?.request?.id}}`}
                    article={article}
                    recipient={mailInfo?.recipient}
                    refereeRequest={mailInfo?.request}
                    open={!!mailInfo}
                    replyTo={mailInfo?.replyTo}
                    subject={mailInfo?.subject}
                    onClose={() => setMailInfo(null)} />
    </>}
    </>)
}