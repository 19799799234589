import {DialogProps} from "components/Modal/DialogProps";
import {Form, Input, Select} from "antd";
import TextArea from "antd/es/input/TextArea";
import {useQuery} from "@apollo/client";
import {
  ArticleWithAllFragment,
  GetTemplatesDocument,
  MailInfoFragment,
  RefereeInfoFragment, SendMailAttachmentInput, TemplateType
} from "generated/graphql";
import {formatDate, formatStatus, toSeparatedString} from "utilities/formatters";
import dayjs from "dayjs";
import {useJournal} from "hooks/useJournal";
import {RecipientBox} from "components/Input/RecipientBox";
import {emailList, emailListRequired, required} from "utilities/formRules";
import {useSendMail} from "hooks/useSendMail";
import {useAuth} from "hooks/useAuth";
import {AttachmentControl} from "components/Modal/AttachmentControl";
import {MinimizeModal} from "components/Modal/MinimizeModal";

export interface Recipient {
  displayName: string
  mailAddress?: string | null
  id?: number
}

interface Props {
  type?: TemplateType
  article: ArticleWithAllFragment
  refereeRequest?: RefereeInfoFragment
  recipient?: Recipient
  replyTo?: MailInfoFragment
  subject?: string
}

const prepareAttachments = (values?: SendMailAttachmentInput[]) =>
  values?.map(v => v.file?.originFileObj ? { file: v.file.originFileObj } : { fileId: v.fileId ?? +v.file.uid }) ?? [];

export const MailDialog = ({ open, onClose, type, article, refereeRequest, recipient, replyTo, subject }: DialogProps & Props ) => {
  const [form] = Form.useForm();
  const { sendMail, loading } = useSendMail();
  const journal = useJournal();
  const { user } = useAuth();

  const { data } = useQuery(GetTemplatesDocument, { variables: { type }, skip: !type });

  const handleOk = async () => {
    const { attachments, bodyType, ...values } = await form.validateFields();
    await sendMail({
      ...values,
      attachments: prepareAttachments(attachments),
      articleId: article.id,
      replyToId: replyTo?.id,
      refereeRequestId: refereeRequest?.id,
      templateType: type,
      sendAsHtml: bodyType === "html"
    });
    onClose();
  };

  const format = (source: string) => {
    let result = source;

    if (article) {
      result = result.replaceAll("AUTHORS", toSeparatedString(article.authors.map(a => a.displayName), ", ", "and"));
      result = result.replaceAll("MANUSCRIPT", article.number.toString());
      result = result.replaceAll("TITLE", article.title);
      result = result.replaceAll("NEWSTATUS", formatStatus(article.status) ?? "");
      result = result.replaceAll("EDITOR", article.handlingEditor?.displayName ?? "");
      result = result.replaceAll("MANAGING", article.managingEditor?.displayName ?? "");
      result = result.replaceAll("COMMENTS", article.refereeRequests
          .filter(r => r.formSubmission)
          .map((r,i) => `Comments from referee ${i + 1}:\n${r.formSubmission?.answers.filter(a => a.text)[0]?.text}`)
          .join('\n\n'));
    }
    if (refereeRequest) {
      result = result.replaceAll("USER", refereeRequest.referee.mailAddress ?? "");
      result = result.replaceAll("LASTNAME", refereeRequest.referee.lastName ?? "");
      result = result.replaceAll("NAME", refereeRequest.referee.displayName ?? "");
      result = result.replaceAll("DEADLINE", formatDate(refereeRequest.dateEnd));
      result = result.replaceAll("REQUESTTIME", formatDate(refereeRequest.dateRequested));
      result = result.replaceAll("TIME", refereeRequest.duration.toString());
      result = result.replaceAll("DAYSOVERDUE", dayjs().diff(dayjs(refereeRequest.dateEnd), 'day').toString());
      result = result.replaceAll("PUBLICURL", `${journal.url}/referee/${refereeRequest.urlToken}`);
    } else if (article) {
      result = result.replaceAll("LASTNAME", article.author.lastName);
      result = result.replaceAll("NAME", article.author.displayName);
    }
    result = result.replaceAll("URL", `${journal.url}/portal`);
    result = result.replaceAll("LINK", `${journal.url}/portal`);

    return result;
  }

  const applyTemplate = (obj: any) => {
    const id: number = obj.key;
    const template = data?.templates.filter(t => t.id === id)[0];
    if (!template?.body || !template.subject) return;

    form.setFieldValue("subject", format(template.subject));
    form.setFieldValue("body", format(template.body));
  }

  return <MinimizeModal title={replyTo ? `Send reply to ${replyTo.from?.displayName}` : "Send email"}
                open={open}
                onOk={handleOk}
                confirmLoading={loading}
                onCancel={onClose}
                maskClosable={false}
                width="600px"
  >
    <Form form={form} labelCol={{ span: 5 }} wrapperCol={{ span: 20 }} labelAlign="left" requiredMark={false}
      initialValues={recipient ? { to: [ { mailAddress: recipient.mailAddress, displayName: recipient.displayName, id: recipient.id } ], subject } : {}}>
      <Form.Item label="From">
        { journal.name }
      </Form.Item>
      { !replyTo && <Form.Item name="to" label="To" rules={emailListRequired}>
        <RecipientBox />
      </Form.Item> }
      <Form.Item name="subject" label="Subject" rules={replyTo ? [] : required}>
        { !replyTo && <Input /> }
        { replyTo && replyTo.subject }
      </Form.Item>
      <Form.Item name="bcc" label="Bcc" rules={emailList}>
        <RecipientBox includeSelf includeEditors />
      </Form.Item>
      <Form.Item name="attachments" label="Attachments">
        <AttachmentControl articleId={article.id} showHidden={type !== TemplateType.Author} />
      </Form.Item>
      { !replyTo && <Form.Item name="bodyType" label="Message type" initialValue="html">
        <Select options={[{ value: "plain", label: "Plain text" }, { value: "html", label: "HTML" }]} />
      </Form.Item> }
      { data && data?.templates.length > 0 && <Form.Item name="templateId" label="Template">
        <Select
            options={data.templates
              .filter(t => !t.userId || t.userId === user?.id || t.userId === article?.managingEditor?.id)
              .map(t => ({ key: t.id, label: t.name, value: t.id, className: t.separatorBelow ? 'separator' : undefined }))
            }
            onChange={(_, e) => applyTemplate(e)} />
      </Form.Item> }
      <Form.Item name="body" rules={required} wrapperCol={{ span: 24 }}>
        <TextArea rows={replyTo ? 10 : 14} />
      </Form.Item>
      { replyTo && <div>
          <div><b>Original message</b></div>
          <iframe style={{ height: "200px", marginTop: "3px" }}
              title="body" srcDoc={replyTo.isHtml ? replyTo.body : replyTo.body.replaceAll("\n", "\n<br/>")} />
      </div>}
    </Form>
  </MinimizeModal>
}