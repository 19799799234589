import {Select, Spin} from "antd";
import {useState} from "react";
import {useQuery} from "@apollo/client";
import {AccessLevel, FindUsersDocument, GetUsersDocument} from "generated/graphql";
import {Recipient} from "components/Modal/MailDialog";
import {useAuth} from "hooks/useAuth";
import {useJournal} from "hooks/useJournal";

interface Props {
  value?: Recipient[]
  onChange?: (value: Recipient[]) => void
  includeSelf?: boolean
  includeEditors?: boolean
}

const toOption = (u: Recipient) => ({
  value: u.mailAddress,
  label: u.displayName ? `${u.displayName} (${u.mailAddress})` : u.mailAddress,
  key: u.id
});
const toRecipient = (u: any) => {
  let value: string = u.value, name = u.label?.split('(')[0].trim();
  if (value.includes('<')) {
    const parts = value.split(/[<>]/);
    value = parts[1];
    name = parts[0].trim();
  }

  return {
    mailAddress: value,
    displayName: name,
    id: u.key
  };
}

export const RecipientBox = ({value, onChange, includeSelf = false, includeEditors = false}: Props) => {
  const [query, setQuery] = useState('');
  const { data, loading } = useQuery(FindUsersDocument, { variables: { query }, skip: query.length < 3 });
  const { data: editorData } = useQuery(GetUsersDocument, {
    variables: { level: AccessLevel.Editor },
    skip: !includeEditors
  });

  const { user } = useAuth();
  const journal = useJournal();

  const options = data?.findUsers.map(toOption) ?? [
    ...(user && includeSelf ? [toOption(user)] : []),
    ...(journal && includeEditors ? [{ value: journal.editorAddress, label: `Editors (${journal.editorAddress})`, key: -1 }] : []),
    ...(editorData?.users.filter(u => u.mailAddress !== user?.mailAddress).map(toOption) ?? [])
  ];

  return <Select
    labelInValue
    mode="tags"
    filterOption={false}
    onSearch={setQuery}
    notFoundContent={loading ? <Spin /> : null}
    value={value?.map(toOption)}
    onChange={v => onChange?.(v.map(toRecipient))}
    options={options}
  />
}