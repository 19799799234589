import {FormRule} from "antd";

export const required = [ { required: true } ];
export const password = [ { required: true, min: 8 } ];
export const email: { required: boolean, type: 'email'}[]
  = [{ required: true, type: "email" }];

const emailRegex =
  new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "i");

const emailListEntry: FormRule = {
  validator: (_, opts: any[]) => {
    if (!opts) return Promise.resolve();
    const wrong = opts.map(o => o.mailAddress).filter(o => !emailRegex.test(o));
    if (wrong.length === 0) return Promise.resolve();
    return Promise.reject(new Error(`Not a valid e-mail address: ${wrong.join(', ')}`))
  }};

export const emailListRequired = [{ required: true }, emailListEntry];
export const emailList = [emailListEntry];